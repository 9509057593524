import * as React from 'react';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import {Speech} from '../components/agenda/AgendaRow';
import {GridItemSize} from '../components/soul/layout/grid/GridItemSize';
import SEO from '../components/SEO/SEO';
import keynoteIcon from '../assets/agenda/ICON_whiteicon_bluebackground_keynote.svg';
import industryIcon from '../assets/agenda/ICON_whiteicon_bluebackground_industry.svg';
import speakersIcon from '../assets/agenda/ICON_whiteicon_bluebackground_corner.svg';
import workshopIcon from '../assets/agenda/ICON_whiteicon_bluebackground_workshop.svg';
import knowIcon from '../assets/agenda/ICON_whiteicon_bluebackground_know.svg';
import Flag from '../components/soul/layout/flag/Flag';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface AgendaPageProps {
    data: {
        allFile: {
            nodes: Speech[]
        }
    }
}

const AgendaPage: React.FC<AgendaPageProps> = ({data}) => {

    return (
        <SingleColumnLayout>
            <SEO title="ESTECO UM22 | Discover the agenda"
                 description="Discover ESTECO UM22 agenda. Join and learn how engineering professionals from industry and academia are facing the future of product development."/>
            <div className="centered-column">
                <h1 className="page-title">Sessions</h1>
                <div className="soul-content--m  soul-font-size-l">
                    <p>
                        What types of sessions are you interested in? This year we’re going to offer more networking opportunities, the more
                        challenging but also more engaging sessions to be organized remotely.</p>
                    <p className="soul-space-stack-bottom-m"><b>Have a look at the <a class="c-link"
                                                                                   href="https://www.accelevents.com/e/esteco-users-meeting-2022#agenda">agenda</a> of
                        the meeting to discover them all on and bookmark the sessions of your interest.</b>
                    </p>
                    <p className="soul-space-stack-bottom-m">Join our event to discover them all:
                    </p>

                    <Flag>
                        <div>
                            <img src={keynoteIcon} alt="Users' meeting logo" className="ew-agenda-icon"/>
                        </div>
                        <div><p className="soul-space-stack-bottom-m"><b>Keynote speakers</b> with <b>Live Q&A</b>: presentations from our
                            first-rate speakers about the most innovative applications of ESTECO Technology. At the end of each session,
                            you’ll have the opportunity to interact with speakers for questions and comments.
                        </p>
                        </div>
                    </Flag>
                    <div className="soul-space-stack-bottom-m"></div>
                    <Flag>
                        <div>
                            <img src={workshopIcon} alt="Users' meeting logo" className="ew-agenda-icon"/>
                        </div>
                        <p className="soul-space-stack-bottom-m"><b>Interactive workshops:</b> hands-on technology focus sessions with our 
                            experts on 30 June. More details about <b>ADAS</b> and <b>Intercax Workshop</b> available <a 
                            className="c-link" href="https://www.accelevents.com/e/esteco-users-meeting-2022#agenda">on the agenda</a>. Limited seats available.
                        </p>
                    </Flag>
                    <div className="soul-space-stack-bottom-m"></div>
                    <Flag>
                        <div>
                            <img src={industryIcon} alt="Users' meeting logo" className="ew-agenda-icon"/>
                        </div>
                        <p className="soul-space-stack-bottom-m"><b>Industry tracks:</b> on-demand sessions to know more about practical
                            applications and cases from our customers.
                        </p>
                    </Flag>
                    <div className="soul-space-stack-bottom-m"></div>
                    <Flag>
                        <div>
                            <img src={speakersIcon} alt="Users' meeting logo" className="ew-agenda-icon"/>
                        </div>
                        <p className="soul-space-stack-bottom-m"><b>modeFRONTIER and VOLTA Trainings:</b> learn more about our products. After the training sessions, join the live Q&A sessions to talk to our experts.
                        </p>
                    </Flag>
                    <div className="soul-space-stack-bottom-m"></div>
                    <Flag>
                        <div>
                            <img src={knowIcon} alt="Users' meeting logo" className="ew-agenda-icon"/>
                        </div>
                        <p className="soul-space-stack-bottom-m"><b>Get to know us:</b> networking area to discover more about our software
                            solutions, get in touch with developers or book a demo to see our technology in action.
                        </p>
                    </Flag>
                    <div className="soul-space-stack-bottom-m"></div>
                </div>
            </div>
        </SingleColumnLayout>
    );
};

export default AgendaPage;
